import { useMemo } from 'react';

import { GenericBulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

export function useVisibleBulkActions<T extends OBJ>(
  actions: GenericBulkAction<T>[] | undefined
): GenericBulkAction<T>[] {
  const { selection } = useTableSelection<T>();

  return useMemo(() => {
    return (actions ?? []).filter((action) => {
      if (typeof action.isVisible === 'function') {
        return action.isVisible(selection);
      }

      return true;
    });
  }, [actions, selection]);
}
