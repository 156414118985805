import { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { MobileBulkActionConfirmButton } from './MobileBulkActionConfirmButton';
import { MobileBulkActionStandardButton } from './MobileBulkActionStandardButton';
import { ActionGroup } from './TableBulkActionMenu';
import { useTableSelection } from './TableSelection';
import { OBJ } from './types';
import { useVisibleBulkActions } from './use-visible-bulk-actions';

export const BulkActionGroupMobile = <T extends OBJ>(
  group: ActionGroup<T>
): ReactElement => {
  const { isSelecting } = useTableSelection();
  const visibleActions = useVisibleBulkActions(group.actions);

  return (
    <Flex
      flexDirection="column"
      isAttached
      {...group.styleProps}
      isDisabled={isSelecting}
    >
      {visibleActions.map((action) => {
        if (action.needsConfirm) {
          return (
            <MobileBulkActionConfirmButton key={action.name} action={action} />
          );
        }

        return (
          <MobileBulkActionStandardButton key={action.name} action={action} />
        );
      })}
    </Flex>
  );
};
