import { IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { RxHamburgerMenu } from 'react-icons/rx';

import { useIsMobile } from '@bq/components/use-is-mobile';

import { BulkActionGroupMobile } from './BulkActionGroupMobile';
import { ActionGroup } from './TableBulkActionMenu';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  isSelecting: boolean;
  actionGroups: ActionGroup<T>[];
}

export const CardBulkActionsMobile = <T extends OBJ>({
  isSelecting,
  actionGroups,
}: Props<T>) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isSelecting && isMobile && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<RxHamburgerMenu />}
            variant="outline"
          />
          <MenuList>
            {actionGroups.map((group, idx) => (
              <BulkActionGroupMobile key={idx} {...group} />
            ))}
          </MenuList>
        </Menu>
      )}
    </>
  );
};
