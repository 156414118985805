module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"content",{"name":"content","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":56,"column":12}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page\">\n	<div class=\"top-section\">\n		<div class=\"header-info\">\n			<div class=\"header-left\">\n				BAUPROM d.o.o.\n			</div>\n			<div class=\"header-right\">\n				Datum ispisa: "
    + container.escapeExpression(lookupProperty(helpers,"format_date").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"dateNow")),lookupProperty(helpers,"tr").call(alias1,"format.date",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":74}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":76}}}))
    + "\n			</div>\n		</div>\n		<h1 style=\"text-align: center;\">Prijevoznice</h1>\n	</div>\n\n	<div class=\"middle-section\">\n		<table>\n			<thead>\n				<tr>\n					<th>#</th>\n					<th>Ticket #</th>\n					<th>Ime kupca</th>\n					<th>Adresa i kontakt</th>\n					<th>Račun br.</th>\n					<th>Naziv artikla</th>\n					<th>Količina</th>\n					<th>Potpis</th>\n				</tr>\n			</thead>\n			<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":51,"column":13}}})) != null ? stack1 : "")
    + "			</tbody>\n		</table>\n	</div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"artikli") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":50,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":5},"end":{"line":43,"column":12}}})) != null ? stack1 : "")
    + "					<td "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":9},"end":{"line":44,"column":50}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</td>\n					<td "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":9},"end":{"line":45,"column":50}}})) != null ? stack1 : "")
    + ">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":5},"end":{"line":48,"column":12}}})) != null ? stack1 : "")
    + "				</tr>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<td class=\"group-start\" rowspan=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"nth") : depths[1]), depth0))
    + ".</td>\n					<td class=\"group-start\" rowspan=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"ID") : depths[1]), depth0))
    + "</td>\n					<td class=\"group-start\" rowspan=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"imeKupca") : stack1), depth0))
    + "</td>\n					<td class=\"group-start\" rowspan=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n						"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"adresa") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"mjesto") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":39,"column":83}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"telefon") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":83},"end":{"line":40,"column":32}}})) != null ? stack1 : "")
    + "\n					</td>\n					<td class=\"group-start\" rowspan=\""
    + alias2(alias1(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"racunBr") : stack1), depth0))
    + "</td>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"mjesto") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ",\n						"
    + container.escapeExpression(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"telefon") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    return "class=\"group-start\" ";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<td class=\"group-start\" rowspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"data") : depths[1])) != null ? lookupProperty(stack1,"artikli") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\" style=\"width: 200px;\"></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"printLayout",{"name":"extend","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":57,"column":11}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true})