import { ReactElement } from 'react';

import { ListingGrid, ListingGridItem } from '@bq/components/ListingGrid';
import { LoadingPage } from '@bq/components/LoadingPage';
import { PageWrapper } from '@bq/components/PageWrapper';
import { Sidebar } from '@bq/components/Sidebar';
import { TreeOverviews } from '@bq/components/TreeOverviews';
import { MobileOverviewWrapper } from '@bq/components/TreeOverviews/MobileOverviewsWrapper';
import { useGetQueryParams } from '@bq/components/use-get-query-params';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { DatasetEntriesTable } from '../components/DatasetEntriesList';
import { useDataset } from '../components/DatasetForm';
import { useGenericAllOverview } from '../components/generic-all-overview';
import { useDatasetID } from './use-dataset-id';

export const DatasetView = (): ReactElement => {
  const datasetID = useDatasetID();
  const { data: dataset } = useDataset(datasetID);
  const titleName = dataset?.name ?? '...';
  useAppTitle(`View "${titleName}" | Datasets`);

  return (
    <PageWrapper p={0} maxH="fullNoNavbar" className="pageWrapper">
      <ListingGrid className="fullPageListingGrid">
        <ListingGridItem minWidth={250}>
          <Sidebar>
            <MobileOverviewWrapper>
              <TreeOverviews
                path="datasets/list"
                getQueryParams={useGetQueryParams()}
                overviews={useGenericAllOverview()}
                selectedOverview=""
              />
            </MobileOverviewWrapper>
          </Sidebar>
        </ListingGridItem>
        <ListingGridItem
          initialWidth="6fr"
          className="ticketSplitViewContainer"
          display="flex"
          flexDirection="column"
        >
          {dataset ? <DatasetEntriesTable {...dataset} /> : <LoadingPage />}
        </ListingGridItem>
      </ListingGrid>
    </PageWrapper>
  );
};
