import { stringify as stringifyQuery } from 'qs';
import { FaPrint, FaTableList } from 'react-icons/fa6';

import { TicketBulkActionProvider } from 'app/Modules/Ticketing/Assets/components/TicketTable/BulkActions/types';
import { Ticket } from 'app/Modules/Ticketing/Assets/components/types';
import { printUrl } from 'BootQuery/Assets/js/print-url';

export const ticketBulkActions: TicketBulkActionProvider = {
  actions: {
    baupromIspis: {
      name: 'Ispis',
      icon: <FaPrint />,
      displayText: true,
      isAllowed: (_selection) => true,
      isVisible: (selection) => {
        if (allTicketsAreOfType(selection, prijevoznicaTypes)) {
          return true;
        }

        return false;
      },
      handler: async (selection) => {
        if (allTicketsAreOfType(selection, prijevoznicaTypes)) {
          await printPrijevoznice(selection);
        }
        console.log('Ispisen sie: ', selection);
      },
      needsConfirm: false,
    },
    baupromIspisBulk: {
      name: 'Ispis zbirno',
      icon: <FaTableList />,
      displayText: true,
      isAllowed: (_selection) => true,
      isVisible: (selection) => {
        if (allTicketsAreOfType(selection, prijevoznicaTypes)) {
          return true;
        }

        return false;
      },
      handler: async (selection) => {
        if (allTicketsAreOfType(selection, prijevoznicaTypes)) {
          await printPrijevozniceZbirno(selection);
        } else {
          throw new Error('Not implemented');
        }
      },
      needsConfirm: false,
    },
  },
};

async function printPrijevoznice(selection: Ticket[]): Promise<void> {
  const ticketIds = selection.map((ticket) => ticket.ID);
  const queryStr = stringifyQuery({ ticketIds }, { addQueryPrefix: false });

  await printUrl(`/bauprom/print/prijevoznica?${queryStr}`);
}

async function printPrijevozniceZbirno(selection: Ticket[]): Promise<void> {
  const ticketIds = selection.map((ticket) => ticket.ID);
  const queryStr = stringifyQuery({ ticketIds }, { addQueryPrefix: false });

  await printUrl(`/bauprom/print/prijevozniceZbirno?${queryStr}`);
}

const prijevoznicaTypes = ['interniVlastitiPrijevoz', 'vanjskiPrijevoz'];

function allTicketsAreOfType(
  selection: Ticket[],
  slugs: string | string[]
): boolean {
  slugs = typeof slugs === 'string' ? [slugs] : slugs;

  return selection.every((ticket) => slugs.includes(ticket.ticketType.slug));
}
