import { ReactElement } from 'react';
import { ButtonGroup } from '@chakra-ui/react';

import { BulkActionButton } from './BulkActionButton';
import { ActionGroup } from './TableBulkActionMenu';
import { useTableSelection } from './TableSelection';
import { OBJ } from './types';
import { useVisibleBulkActions } from './use-visible-bulk-actions';

export const BulkActionGroup = <T extends OBJ>(
  group: ActionGroup<T>
): ReactElement => {
  const { isSelecting } = useTableSelection();
  const visibleActions = useVisibleBulkActions(group.actions);

  return (
    <ButtonGroup isAttached isDisabled={isSelecting} {...group.styleProps}>
      {visibleActions.map((action) => (
        <BulkActionButton key={action.name} {...action} />
      ))}
    </ButtonGroup>
  );
};
