import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Provider,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OBJ } from './types';

type ToggleSelected<T extends OBJ> = (row: T, selected?: boolean) => void;
type ClearSelected = () => void;

type SelectionSetter<T extends OBJ> = Dispatch<SetStateAction<T[]>>;
export interface ITableSelection<T extends OBJ = OBJ> {
  selection: T[];
  setSelection: SelectionSetter<T>;
  toggleSelected: ToggleSelected<T>;
  clearSelected: ClearSelected;
  selectPage?: () => void;
  selectAll?: () => void;
  allSelected: boolean;
  idColumn: keyof T;
  isSelecting: boolean;
}

export interface BulkAction<T extends OBJ, U extends OBJ = T> {
  name: string;
  icon: ReactElement;
  isAllowed: (selection: U[]) => boolean;
  isVisible?: (selection: U[]) => boolean;
  handler: (selection: U[], rawSelection: T[]) => Promise<void>;
  onActionEnd?: (selection: U[]) => void;
  parseSelection?: (selection: T[]) => U[];
  needsConfirm: boolean;
  disabledTooltip?: string;
  displayText?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericBulkAction<T extends OBJ> = BulkAction<T, any>;

export const TableSelectionContext = createContext<ITableSelection | null>(
  null
);

interface ProviderProps<T extends OBJ> {
  idColumn: keyof T;
  selectPage?: () => T[];
  selectAll?: () => Promise<T[]>;
  page: number | null;
}

export const TableSelectionProvider = <T extends OBJ = OBJ>({
  children,
  idColumn,
  selectPage,
  selectAll,
  page,
}: PropsWithChildren<ProviderProps<T>>): ReactElement => {
  const [selection, setSelection] = useState<T[]>([]);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const toggleSelected = useToggleSelected<T>(setSelection, idColumn);
  const clearSelected = useClearSelected<T>(setSelection);

  // on new page clear selection
  useEffect(() => {
    clearSelected();
  }, [clearSelected, page]);
  // handler to select whole page
  const onSelectPage = useCallback(() => {
    setSelection(selectPage ? selectPage() : []);
  }, [selectPage]);
  // handler to select all items that satisfy a filter
  const onSelectAll = useCallback(async () => {
    setIsSelecting(true);
    if (selectAll) {
      const rows = await selectAll();
      setSelection(rows);
    } else {
      setSelection([]);
    }
    setIsSelecting(false);
  }, [selectAll]);
  // current visible items
  const allSelected = useMemo(() => {
    const allItems = selectPage ? selectPage() : [];

    return selection >= allItems;
  }, [selectPage, selection]);

  const Provider =
    TableSelectionContext.Provider as Provider<ITableSelection<T> | null>;

  return (
    <Provider
      value={{
        selection,
        setSelection,
        toggleSelected,
        clearSelected,
        selectPage: onSelectPage,
        selectAll: onSelectAll,
        allSelected,
        idColumn,
        isSelecting,
      }}
    >
      {children}
    </Provider>
  );
};

export function useTableSelection<T extends OBJ = OBJ>(): ITableSelection<T> {
  const context = useContext(TableSelectionContext);

  if (!context) {
    throw new Error('Tried to useTableSelection without context');
  }

  return context as unknown as ITableSelection<T>;
}

function useToggleSelected<T extends OBJ>(
  setSelection: SelectionSetter<T>,
  idColumn: keyof T
): ToggleSelected<T> {
  return useCallback(
    (row, selected) => {
      setSelection((prev) => {
        const currentlySelected = prev.some(
          (prevRow) => prevRow[idColumn] === row[idColumn]
        );
        const shouldSelect =
          typeof selected === 'boolean' ? selected : !currentlySelected;

        if (shouldSelect && !currentlySelected) {
          return [...prev, row];
        }
        if (!shouldSelect && currentlySelected) {
          return prev.filter((prevRow) => prevRow[idColumn] !== row[idColumn]);
        }

        return prev;
      });
    },
    [idColumn, setSelection]
  );
}

function useClearSelected<T extends OBJ>(
  setSelection: SelectionSetter<T>
): ClearSelected {
  return useCallback(() => {
    setSelection([]);
  }, [setSelection]);
}

// function useSelectAllVisible(
//   setSelection: SelectionSetter,
//   allElements: IdTypes[]
// ): SelectAllVisible {
//   return useCallback(() => {
//     setSelection(allElements);
//   }, [allElements, setSelection]);
// }
