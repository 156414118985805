import { ReactElement } from 'react';

import { BulkActionConfirmButton } from './BulkActionConfirmButton';
import { BulkActionStandardButton } from './BulkActionStandardButton';
import { BulkActionTextButton } from './BulkActionTextButton';
import { GenericBulkAction } from './TableSelection';
import { OBJ } from './types';

export const BulkActionButton = <T extends OBJ>(
  action: GenericBulkAction<T>
): ReactElement => {
  if (action.needsConfirm) {
    return <BulkActionConfirmButton key={action.name} action={action} />;
  }
  if (action.displayText === true) {
    return <BulkActionTextButton key={action.name} action={action} />;
  }

  return <BulkActionStandardButton action={action} key={action.name} />;
};
