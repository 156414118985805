import Module from 'BootQuery/Assets/js/module';

import { ticketBulkActions } from './ticket-bulk-actions-provider';

export default class Bauprom extends Module {
  get provides() {
    return {
      ticketBulkActions,
    };
  }
}
