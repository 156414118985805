import { useParams } from 'react-router-dom';

/**
 * Get the route param `datasetID` and ensure it is a number.
 * Throws an error if it's missing or invalid.
 */
export function useDatasetID(): number {
  const { datasetID } = useParams();
  if (!datasetID) {
    throw new Error('Missing dataset ID');
  }

  const datasetIDNumeric = parseInt(datasetID, 10);
  if (Number.isNaN(datasetIDNumeric)) {
    throw new Error('Invalid dataset ID');
  }

  return datasetIDNumeric;
}
