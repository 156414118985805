import { ReactElement } from 'react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { PageWrapper } from '@bq/components/PageWrapper';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { DatasetEditForm } from '../components/DatasetForm/DatasetEditForm';
import { useDataset } from '../components/DatasetForm/use-dataset';
import { useDatasetID } from './use-dataset-id';

export const DatasetEdit = (): ReactElement => {
  const datasetID = useDatasetID();

  const { data: dataset } = useDataset(datasetID);
  const titleName = dataset?.name ?? '...';
  useAppTitle(`Edit "${titleName}" | Datasets`);

  return (
    <PageWrapper>
      {dataset ? <DatasetEditForm dataset={dataset} /> : <LoadingPage />}
    </PageWrapper>
  );
};
