import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  GetOneParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { DatasetEntryListItem } from '../DatasetEntriesList/schema';

export type ListDatasetEntriesResponse = ListingResponse<DatasetEntryListItem>;

export async function listDatasetEntries(
  datasetID: number,
  params: GetListingParams
): Promise<ListDatasetEntriesResponse> {
  const { data } = await Api.get<ListDatasetEntriesResponse>(
    `/api/datasets/datasets/${datasetID}/entries`,
    { params }
  );

  return data;
}

export async function getDatasetEntries<
  D extends Partial<DatasetEntryListItem> = DatasetEntryListItem,
>(datasetID: number, entryID: number, params: GetOneParams = {}): Promise<D> {
  const { data } = await Api.get<D>(
    `/api/datasets/datasets/${datasetID}/entries/${entryID}`,
    {
      params,
    }
  );

  return data;
}
